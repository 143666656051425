var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[(_vm.show)?_c('v-container',{staticClass:"py-0 submenu",class:{
      'background-black': _vm.appBarChange == 0,
      'background-white': _vm.appBarChange == 1,
    },attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center py-10",attrs:{"cols":"auto"}},[(_vm.appBarChange == 0)?[_c('router-link',{staticClass:"menu-app-item d-inline-block rewrite-font",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/menu"}},[_vm._v("Menú")]),_c('router-link',{staticClass:"menu-app-item d-inline-block rewrite-font px-4",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/contacto"}},[_vm._v("Contacto")])]:[_c('router-link',{staticClass:"menu-app-item d-block rewrite-font",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/"}},[_vm._v("Home")])],_c('a',{staticClass:"menu-app-item d-inline-block rewrite-font",class:{
            'color-white pt-7': _vm.appBarChange == 0,
            'color-black': _vm.appBarChange == 1,
          },attrs:{"href":"https://www.wabishop.app/srTanaka?menu=efa8b1ca-3c88-4bdd-95c1-c64807603f0e","target":"_blank"}},[_vm._v("servicio a domicilio")]),_c('a',{staticClass:"menu-app-item outlined d-inline-block rewrite-font",class:{
            'color-white': _vm.appBarChange == 0,
            'color-black mt-7': _vm.appBarChange == 1,
          },attrs:{"href":"https://www.opentable.com.mx/restref/client/?restref=5254&lang=es-MX&ref=10792&fbclid=IwAR2BZUVqFF2PuPmznPeiaMohgb6lWr7I9YESpWrWvK5qnopoPhfa2qdCoLU&corrid=e943a01f-4593-4dd3-bd49-71187f29abde","target":"_blank"}},[_vm._v("Reservaciones")])],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }