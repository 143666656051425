<template>
  <v-expand-transition>
    <v-container
      fluid
      class="py-0 submenu"
      v-bind:class="{
        'background-black': appBarChange == 0,
        'background-white': appBarChange == 1,
      }"
      v-if="show"
    >
      <v-row justify="center">
        <v-col cols="auto" class="text-center py-10">
          <template v-if="appBarChange == 0">
            <router-link
              to="/menu"
              class="menu-app-item d-inline-block rewrite-font"
              v-bind:class="{
                'color-white': appBarChange == 0,
                'color-black': appBarChange == 1,
              }"
              >Menú</router-link
            >

            <router-link
              to="/contacto"
              class="menu-app-item d-inline-block rewrite-font px-4"
              v-bind:class="{
                'color-white': appBarChange == 0,
                'color-black': appBarChange == 1,
              }"
              >Contacto</router-link
            >
          </template>
          <template v-else>
            <router-link
              to="/"
              class="menu-app-item d-block rewrite-font"
              v-bind:class="{
                'color-white': appBarChange == 0,
                'color-black': appBarChange == 1,
              }"
              >Home</router-link
            >
          </template>

          <a
            href="https://www.wabishop.app/srTanaka?menu=efa8b1ca-3c88-4bdd-95c1-c64807603f0e"
            class="menu-app-item d-inline-block rewrite-font"
            v-bind:class="{
              'color-white pt-7': appBarChange == 0,
              'color-black': appBarChange == 1,
            }"
            target="_blank"
            >servicio a domicilio</a
          >

          <a
            href="https://www.opentable.com.mx/restref/client/?restref=5254&lang=es-MX&ref=10792&fbclid=IwAR2BZUVqFF2PuPmznPeiaMohgb6lWr7I9YESpWrWvK5qnopoPhfa2qdCoLU&corrid=e943a01f-4593-4dd3-bd49-71187f29abde"
            class="menu-app-item outlined d-inline-block rewrite-font"
            v-bind:class="{
              'color-white': appBarChange == 0,
              'color-black mt-7': appBarChange == 1,
            }"
            target="_blank"
            >Reservaciones</a
          >
        </v-col>
      </v-row>
    </v-container>
  </v-expand-transition>
</template>

<script>
export default {
  name: "subMenu",
  props: {
    show: { type: Boolean, required: true },
    appBarChange: { type: Number, required: true },
  },
};
</script>