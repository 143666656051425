<template>
  <div class="Header">
    <template v-if="showAppBar">
      <v-app-bar
        app
        class="header-appbar"
        elevation="0"
        v-bind:color="colorAppBar"
        v-bind:class="{
          'background-black': appBarChange == 0,
          'background-white': appBarChange == 1,
        }"
        v-bind:height="heightAppBar"
        fixed
      >
        <v-row align="center" justify="end">
          <v-col>
            <router-link
              to="/"
              class="not-text-decoration"
              v-bind:class="{
                'color-white': appBarChange == 0,
                'color-black': appBarChange == 1,
              }"
            >
              <span class="titles-app rewrite-font-2">
                SEÑOR TANAKA 田中氏
              </span>
            </router-link>
          </v-col>

          <!-- botón de menú -->
          <v-col
            class="d-md-none"
            cols="auto"
            md="6"
            @click="open_submenu = !open_submenu"
          >
            <v-img
              :src="open_submenu ? menuIconClose : menuIcon"
              class="menu-logo"
              :class="{ 'menu-close': open_submenu }"
            ></v-img>
          </v-col>
          <!-- /botón de menú -->

          <!-- del menú en desk -->
          <template v-if="appBarChange == 0">
            <v-col cols="auto" class="px-0 d-none d-md-block">
              <router-link
                to="/menu"
                class="menu-app-item d-inline-block px-4"
                v-bind:class="{
                  'color-white': appBarChange == 0,
                  'color-black': appBarChange == 1,
                }"
                >Menú</router-link
              >
            </v-col>
            <!-- <v-col cols="auto" class="px-0">
              <router-link to="/temporada" class="menu-app-item d-inline-block px-4" v-bind:class="{'color-white': appBarChange == 0, 'color-black': appBarChange == 1,}">Temporada</router-link>
            </v-col> -->
            <v-col cols="auto" class="px-0 d-none d-md-block">
              <router-link
                to="/contacto"
                class="menu-app-item d-inline-block px-4"
                v-bind:class="{
                  'color-white': appBarChange == 0,
                  'color-black': appBarChange == 1,
                }"
                >Contacto</router-link
              >
            </v-col>
          </template>
          <template v-if="appBarChange == 1">
            <v-col cols="auto" class="px-0 d-none d-md-block">
              <router-link
                to="/"
                class="menu-app-item d-inline-block px-4"
                v-bind:class="{
                  'color-white': appBarChange == 0,
                  'color-black': appBarChange == 1,
                }"
                >Home</router-link
              >
            </v-col>
          </template>
          <v-col cols="auto" class="px-0 d-none d-md-block">
            <a
              href="https://www.wabishop.app/srTanaka?menu=efa8b1ca-3c88-4bdd-95c1-c64807603f0e"
              class="menu-app-item d-inline-block px-4"
              v-bind:class="{
                'color-white': appBarChange == 0,
                'color-black': appBarChange == 1,
              }"
              target="_blank"
              >servicio a domicilio</a
            >
          </v-col>
          <v-col cols="auto" class="px-0 d-none d-md-block">
            <a
              href="https://www.opentable.com.mx/restref/client/?restref=5254&lang=es-MX&ref=10792&fbclid=IwAR2BZUVqFF2PuPmznPeiaMohgb6lWr7I9YESpWrWvK5qnopoPhfa2qdCoLU&corrid=e943a01f-4593-4dd3-bd49-71187f29abde"
              class="menu-app-item outlined d-inline-block px-4"
              v-bind:class="{
                'color-white': appBarChange == 0,
                'color-black': appBarChange == 1,
              }"
              target="_blank"
              >Reservaciones</a
            >
          </v-col>
          <!-- /del menú en desk -->
        </v-row>
      </v-app-bar>

      <!-- submenú -->
      <subMenu :show="open_submenu" :appBarChange="appBarChange"></subMenu>
      <!-- /submenú -->
    </template>
  </div>
</template>
<script>
import subMenu from "../static/SubMenu";
import menuIcon from "@/assets/icons/menu-mobile.svg";
import menuIconClose from "@/assets/icons/menu-mobile-close.svg";

export default {
  name: "Header",
  components: {
    subMenu,
  },
  props: {
    appBarChange: { type: Number, required: true },
  },
  computed: {
    colorAppBar() {
      var blackColor = "#000000";
      var whiteColor = "#FFFFFF";

      if (this.appBarChange == 0) {
        return blackColor;
      } else if (this.appBarChange == 1) {
        return whiteColor;
      }
    },
  },
  data() {
    return {
      itemsNav: [
        {
          text: "menú",
          url: "/menu",
        },
        {
          text: "temporada",
          url: "/temporada",
        },
        {
          text: "contacto",
          url: "/contacto",
        },
        {
          text: "reservaciones",
          url: "https:/www.google.com",
        },
      ],
      open_submenu: false,
      menuIcon,
      menuIconClose,
      heightAppBar: 100,
      showAppBar: false,
    };
  },
  watch: {
    $route(to, from) {
      this.open_submenu = false;
    },
  },
  beforeCreate() {
    setTimeout(() => {
      this.showAppBar = true;
    }, 7000);
  },
  mounted() {
    //resize about height carousel
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize() {
      //height del appbar
      if (window.innerWidth <= 959) {
        this.heightAppBar = 60;
      } else if (window.innerWidth >= 960) {
        this.heightAppBar = 100;
      }
    },
  },
};
</script>